import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
} from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
	card: {
		width: '80%',
		height: '300px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		borderRadius: '1.25rem',
		marginBottom: '2rem',
    paddingTop: '2rem',
		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	ctaLink: {
		marginBottom: '.5rem',
		'&:hover': {
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
	vs: {
		color: 'white',
		background: '#055291',
		borderRadius: '12px',
		padding: '2px 6px',
    fontWeight: 600,
    margin: '2rem auto',
	},
}));

export const CompareCard = ({ card }) => {
	const { title, logo, competitor, ctaText, ctaLink } = card;
	const classes = useStyles();
	return (
		<Grid
			item
			xs={12}
			md={6}
			container
			direction='column'
			justifyContent='space-around'
			alignItems='center'>
			<Card className={classes.card}>
				<CardContent style={{ height: '100%' }}>
					<Grid
						container
						alignItems='center'
						justifyContent='space-between'
						direction='column'
						style={{ height: '100%' }}>
						<GatsbyImage
							image={logo.asset?.gatsbyImageData}
							style={{ marginBottom: '-40px' }}
						/>
						<Typography variant='body2' className={classes.vs}>
							vs
						</Typography>
						<Typography
							variant='h5'
							color='primary'
							style={{ marginTop: '-40px' }}>
							{competitor}
						</Typography>
						<Link to={`/${ctaLink}`} style={{ textDecoration: 'none' }}>
							<Typography
								color='primary'
								className={classes.ctaLink}
								style={{ fontSize: '1.125rem', fontWeight: 600 }}>
								{ctaText} <span className={classes.arrow}>→</span>
							</Typography>
						</Link>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
