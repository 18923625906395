import React from 'react';

import { Container, Grid, Typography } from '@material-ui/core';

import { HighlightCard } from './HighlightCard';
import { CompareCard } from './CompareCard';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';

export const CompareBody = ({ compare }) => {
	const {
		highlightCardHeader,
		highlightCards,
		compareCardHeader,
		compareCards,
	} = compare;
	return (
		<>
			<div style={{ background: '#FFF', marginBottom: '-24rem' }}>
				<Container>
					<Grid
						container
						style={{ paddingTop: '2rem', paddingBottom: '4rem' }}
						justifyContent='center'>
						<Typography variant='h2' color='primary' style={{ zIndex: 100 }}>
							{highlightCardHeader}
						</Typography>
					</Grid>
					<Grid
						container
						spacing={2}
						style={{ paddingBottom: '4rem' }}
						justifyContent='center'>
						{highlightCards.map((card, index) => (
							<HighlightCard card={card} key={index} />
						))}
					</Grid>
				</Container>
			</div>
			<WaveDownSVG shadow fill='#FFF' />
			<div style={{ background: '#EFF2F7', paddingTop: '24rem' }}>
				<Container>
					<Grid
						container
						style={{ paddingBottom: '4rem' }}
						justifyContent='center'>
						<Typography variant='h2' color='primary'>
							{compareCardHeader}
						</Typography>
					</Grid>
					<Grid container style={{ paddingBottom: '4rem' }}>
						{compareCards.map((card, index) => (
							<CompareCard card={card} index={index} />
						))}
					</Grid>
				</Container>
			</div>
		</>
	);
};
